.hbb-recording-translation-view {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.hbb-recording-translation-view .tip {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.277778rem;
}

.hbb-recording-translation-view .tip p {
  display: flex;
  flex-direction: column;
}

.hbb-recording-translation-view .tip p span {
  font-size: 0.388889rem;
}

.hbb-recording-translation-view .tip p span:nth-of-type(1) {
  margin-bottom: 0.416667rem;
}

.hbb-recording-translation-view .tip .hbb-isScore {
  position: relative;
  width: 2.222222rem;
  height: 2.222222rem;
}

.hbb-recording-translation-view .tip .hbb-isScore img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.hbb-recording-translation-view .tip .hbb-isScore b {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  line-height: 1.666667rem;
  justify-content: center;
  left: 0;
  top: 0;
}

.hbb-recording-translation-view .box {
  display: flex;
  width: 100%;
  max-height: 8.333333rem;
  overflow: auto;
  flex-direction: column;
  margin-top: 0.833333rem;
}

.hbb-recording-translation-view .box .hbb-trans-noData {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 0.833333rem;
}

.hbb-recording-translation-view .box .hbb-trans-noData p {
  margin-bottom: 0.277778rem;
}

.hbb-recording-translation-view .box .hbb-trans-noData .el-button {
  width: 4.444444rem;
  margin: 0.555556rem auto 1.944444rem auto;
}

.hbb-recording-translation-view .box .item {
  display: flex;
  margin-bottom: 0.277778rem;
}

.hbb-recording-translation-view .box .left {
  float: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.hbb-recording-translation-view .box .right {
  float: left;
  flex-direction: row-reverse;
}

.hbb-recording-translation-view .box .right .message {
  margin-right: 0.277778rem;
}

.hbb-recording-translation-view .box .left .message {
  margin-left: 0.277778rem;
  background: #e6f0fb 100%;
}

.hbb-recording-translation-view .box .header-img {
  width: 0.833333rem;
  height: 0.833333rem;
  border-radius: 2.777778rem;
}

.hbb-recording-translation-view .box .message {
  border-radius: 0.277778rem;
  float: left;
  max-width: 13.888889rem;
  background: #efefef;
  min-height: 0.694444rem;
  padding: 0.277778rem 0.416667rem;
  color: #222121;
}

.hbb-recording-translation-view .mas-audio {
  display: flex;
  width: calc(100% + 1.111111rem);
  margin-left: -0.555556rem;
  border-top: 0.027778rem solid #1296db;
  margin-top: 0.555556rem;
}

.hbb-recording-translation-view .record-wait-box {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.hbb-recording-translation-view .record-wait-box p {
  width: 100%;
  font-size: 0.388889rem;
  text-align: center;
  color: #101010;
}

.hbb-recording-translation-view .record-wait-box p:last-child {
  margin-top: 0.555556rem;
}