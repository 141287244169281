table {
  empty-cells: show;
  border-collapse: collapse;
}

ul {
  -webkit-list-style-position: inside;
  -moz-list-style-position: inside;
  -ms-list-style-position: inside;
  -o-list-style-position: inside;
  list-style-type: none;
}

li {
  list-style-type: none;
}

.hbb .el-date-editor .el-range-separator {
  width: auto !important;
}

.hbb-left-menu-popper .el-menu--popup {
  border-radius: 0.138889rem;
}

.hbb-left-menu-popper .el-menu--popup .el-menu-item-group__title {
  padding: 0;
}

.hbb-left-menu-popper .el-menu--popup .el-menu-item {
  background-color: #283237;
}

.hbb-left-menu-popper .el-menu--popup .el-menu-item span {
  color: #fff;
}

.hbb-left-menu-popper .el-menu--popup .el-menu-item:hover {
  background: #587add;
}

.hbb-left-menu-popper .el-menu--popup .el-menu-item.active {
  background-color: #587add;
}

.hbb-left-menu-popper {
  padding: 0 !important;
}

.hbb-left-menu-popper .el-menu-item {
  color: #fff;
}

.hbb-left-menu-popper .el-menu-item.active {
  color: #fff;
}

.hbb-left-menu-popper .el-menu--popup {
  padding: 0 !important;
}

.hbb-left-menu-popper .el-menu--popup-right-start {
  margin: 0 !important;
}

.el-popper {
  min-width: auto !important;
}

.el-popper.is-light {
  border: none;
}

.el-menu-item-group>ul {
  background-color: #304156;
}

.hbb-vacation-calendar-modal .is-selected {
  background-color: #fff !important;
}

.hbb-vacation-calendar-modal .el-backtop .day,
.hbb-vacation-calendar-modal .el-calendar-table td.is-today .day {
  color: #409eff !important;
  font-weight: bold;
  font-size: 0.666667rem !important;
}

.hbb-vacation-calendar-modal .el-calendar__header {
  display: none !important;
}

.hbb-vacation-calendar-modal .el-calendar__body {
  padding: 0.555556rem;
}

.hbb-vacation-calendar-modal .el-calendar__body .el-calendar-table thead {
  text-align: center;
  border: 0.027778rem solid #ebeef5;
}

.hbb-vacation-calendar-modal .el-calendar__body .el-calendar-table thead th {
  border-left: 0.027778rem solid #ebeef5;
}

.input-line .el-input__inner {
  border: none !important;
}

.input-line .el-input__inner:hover {
  background: #fff;
}

.el-input__suffix-inner {
  display: flex;
  height: 100%;
}

.el-icon-loading {
  font-size: 0.888889rem;
}

.el-select .el-select__tags-text {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.color-2d75d4 {
  color: #2d75d4;
}

.color-D61515 {
  color: #d61515;
}

@keyframes dynamics {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 100% 0%;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: microsoft yahei,-apple-system,sf ui text,arial,pingfang sc,hiragino sans gb,wenquanyi micro hei,sans-serif;
  line-height: 1.2;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 27.777778rem #fff inset;
  box-shadow: 0 0 0 27.777778rem #fff inset;
  -webkit-text-fill-color: #333;
}

::after,
::before {
  box-sizing: border-box;
}

html {
  font-size: min(1.8vw,3.2vh);
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  background-color: #fff;
}

body {
  overflow-y: hidden;
  background-color: #f1f2f3;
  -webkit-overflow-scrolling: touch;
}

body,
p {
  margin: 0;
  padding: 0;
}

fieldset {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  outline: none;
}

img {
  vertical-align: top;
}

a img {
  border: 0;
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline: 0;
}

#app,
.app-wrapper {
  height: 100%;
  position: relative;
  z-index: 1000;
}

#cnzz_stat_icon_1279803872 {
  position: fixed;
  top: 0;
  z-index: 999;
  opacity: 0;
}

#nprogress .bar {
  background: #587add !important;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing2: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  overflow: hidden;
}

body::before {
  content: "";
  position: fixed;
  top: -0.277778rem;
  left: 0;
  width: 100%;
  height: 0.277778rem;
  -webkit-box-shadow: 0 0 0.277778rem rgba(0,0,0,.3);
  -moz-box-shadow: 0 0 0.277778rem rgba(0,0,0,.3);
  box-shadow: 0 0 0.277778rem rgba(0,0,0,.3);
  z-index: 10000;
}

body {
  overflow: hidden;
  background-size: 1400% 300%;
  animation: dynamics 1s ease infinite;
  -webkit-animation: dynamics 1s ease infinite;
  -moz-animation: dynamics 1s ease infinite;
  font-size: 0.388889rem;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.opacity {
  filter: alpha(opacity=50);
  -moz-opacity: .5;
  -khtml-opacity: .5;
  opacity: .5;
}

.app-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 1.944444rem;
  z-index: 100;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: .0.138889rem solid #ccc;
}

.app-header .l-part {
  display: flex;
  width: 5.555556rem;
  align-items: center;
  background: #283237;
  height: 100%;
  transition-duration: .5s;
}

.app-header .l-part .image-icon {
  width: 5.555556rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.app-header .l-part .image-icon img {
  margin-right: 0.277778rem;
}

.app-header .l-part .image-icon span {
  color: #fff;
  width: 2.777778rem;
  display: block;
  font-size: 0.388889rem;
}

.app-header .l-part .image-icon span.tShow {
  display: none;
}

.app-header .l-part.l-partActive {
  width: 1.777778rem;
}

.app-header .l-part.l-partActive .image-icon img {
  margin-right: 0;
}

.app-header .l-part .welcome-text {
  color: #fff;
  font-size: 0.444444rem;
  align-self: center;
  user-select: none;
}

.app-header .shou-menu {
  display: flex;
  align-items: center;
  position: absolute;
  left: 5.833333rem;
  cursor: pointer;
  height: 1.944444rem;
  transition-duration: .5s;
}

.app-header .shou-menu span {
  font-size: 0.777778rem;
}

.app-header .shou-menu.active {
  left: 2.222222rem;
}

.app-header .right-menu {
  display: flex;
  cursor: pointer;
  margin-right: 5%;
  align-items: center;
}

.app-header .right-menu .item {
  display: inline-block;
  padding: 0 0.277778rem;
  font-size: 0.388889rem;
  color: #333;
  font-weight: bold;
  user-select: none;
}

.app-header .right-menu .item:nth-of-type(2) {
  margin: 0 0.555556rem;
}

.app-header .right-menu .item.work {
  background: #2d75d4;
  display: flex;
  width: 0.833333rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 0.833333rem;
}

.app-main-container {
  position: relative;
  min-height: 100%;
  min-height: calc(100% - 1.944444rem);
}

.app-sidebar {
  width: 5.555556rem;
  height: 100%;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  background: #283237;
  box-shadow: 0 0 0.277778rem 0.138889rem rgba(0,0,0,.05);
  transition-duration: .5s;
  overflow-y: auto;
}

.app-sidebar .nav-scroll {
  padding-top: 1.944444rem;
}

.app-sidebar .hbb-el-aside {
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 1.944444rem;
  display: flex;
}

.app-sidebar .hbb-el-aside .el-menu {
  border-right: 0;
  width: 100%;
  background-color: #283237;
}

.app-sidebar .hbb-el-aside .el-menu .menu-icon {
  color: #fff;
  padding-right: 0.277778rem;
  opacity: .5;
}

.app-sidebar .hbb-el-aside .el-menu .el-menu-item,
.app-sidebar .hbb-el-aside .el-menu .el-menu-item-group {
  color: #ccc;
}

.app-sidebar .hbb-el-aside .el-menu .el-menu-item span,
.app-sidebar .hbb-el-aside .el-menu .el-menu-item-group span {
  color: #fff;
}

.app-sidebar .hbb-el-aside .el-menu .el-menu-item .el-menu-item-group__title,
.app-sidebar .hbb-el-aside .el-menu .el-menu-item-group .el-menu-item-group__title {
  padding: 0;
}

.app-sidebar .hbb-el-aside .el-menu .el-menu-item .el-menu-item,
.app-sidebar .hbb-el-aside .el-menu .el-menu-item-group .el-menu-item {
  background: rgba(0,0,0,.18);
}

.app-sidebar .hbb-el-aside .el-menu .el-menu-item:hover {
  background: #159aff;
  color: #fff;
}

.app-sidebar .hbb-el-aside .el-menu .el-menu-item.active {
  background-color: #159aff;
  color: #fff;
}

.app-sidebar .hbb-el-aside .el-menu .hbb-sidebar-p {
  display: flex;
  width: 100%;
  height: 1.555556rem;
  align-items: center;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu {
  padding: 0;
  width: 100%;
  display: block;
  height: auto;
  cursor: pointer;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu .hbb-sidebar-one {
  display: flex;
  align-items: center;
  height: 1.25rem;
  width: 100%;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu span {
  color: #fff;
  opacity: .8;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu .el-sub-menu__title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 1rem;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu .el-sub-menu__title i {
  color: #fff !important;
  width: 0.555556rem;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu:hover .el-sub-menu__title {
  background-color: #159aff;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu:hover span {
  color: #fff;
  opacity: 1;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu:hover .menu-icon {
  opacity: 1;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu.active {
  background-color: #159aff;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu.active .menu-icon {
  opacity: 1;
}

.app-sidebar .hbb-el-aside .el-menu .el-sub-menu.noChild .el-sub-menu__title .el-sub-menu__icon-arrow {
  display: none !important;
}

.app-sidebar .hbb-el-aside .el-menu .el-menu-item {
  padding-left: 1.666667rem !important;
  height: 1rem;
}

.app-sidebar .hbb-el-aside .el-menu .el-menu-item-group .el-menu-item {
  padding-left: 1.666667rem !important;
  height: 1rem;
}

.app-sidebar.app-sidebarActive {
  width: 1.777778rem;
}

.app-sidebar.app-sidebarActive .cap {
  display: flex;
  justify-content: center;
  text-align: center;
}

.app-sidebar.app-sidebarActive .cap::before {
  display: none;
}

.app-sidebar.app-sidebarActive .el-submenu {
  padding: 0;
}

.app-sidebar.app-sidebarActive .el-submenu:hover .menu-icon {
  opacity: 1 !important;
}

.app-sidebar.app-sidebarActive .el-submenu.active {
  background: none !important;
}

.app-sidebar.app-sidebarActive .el-submenu.active .menu-icon {
  opacity: 1 !important;
}

.app-sidebar.app-sidebarActive .el-submenu.noChild .el-icon-arrow-down {
  display: none !important;
}

.app-sidebar.app-sidebarActive .menu-icon {
  padding-right: 0 !important;
}

.app-sidebar.app-sidebarActive .el-submenu__title {
  justify-content: center;
}

.app-sidebar.app-sidebarActive .el-menu--collapse .el-submenu {
  text-align: center;
}

.app-sidebar::-webkit-scrollbar {
  width: 0.027778rem;
}

.app-main-area {
  background: #f2f2f2;
  position: relative;
  top: 1.944444rem;
  left: 5.555556rem;
  width: calc(100% - 5.555556rem);
  height: calc(100% - 1.944444rem);
  overflow: auto;
  transition-duration: .5s;
}

.app-main-area.active {
  left: 1.777778rem;
  width: calc(100% - 1.777778rem);
}

.nav-common-breadcrumb {
  background-color: #fff;
  display: flex;
  height: 1.111111rem;
  border-top-left-radius: 0.138889rem;
  border-top-right-radius: 0.138889rem;
  padding: 0 0.555556rem;
  align-items: center;
}

.nav-common-breadcrumb.hide {
  display: none;
}

.nav-common-search {
  background-color: #fff;
  margin: 0.277778rem 0 -0.277778rem 0;
  padding: 0.138889rem 0 0.277778rem 0;
  width: 100%;
}

.nav-common-search .search-line {
  display: flex;
  flex-flow: row wrap;
}

.nav-common-search .search-line .search-union {
  display: inline-block;
  margin-right: 0.555556rem;
  margin-bottom: 0.277778rem;
}

.nav-common-search .search-line .search-union .label-text {
  float: left;
  margin-bottom: 0;
  line-height: 0.972222rem;
  text-align: right;
  font-weight: 700;
}

.nav-common-search .search-line .search-union .input-text,
.nav-common-search .search-line .search-union .input-time {
  float: left;
}

.nav-common-search .search-line .search-union .words {
  float: left;
  line-height: 0.972222rem;
  font-weight: bold;
  padding-right: 0.277778rem;
}

.connecting-line {
  display: inline-block;
  min-width: 0.277778rem;
  text-align: center;
  line-height: 0.972222rem;
  margin: 0 0.083333rem;
}

.commom-table-pagination {
  background-color: #fff;
  padding: 0.333333rem 0.555556rem;
  margin-top: 0.277778rem;
  border-radius: 0.138889rem;
}

.common-table {
  background-color: #fff;
  position: relative;
  margin-top: 0.138889rem;
  width: 100%;
}

.common-table .el-table {
  border-bottom: none;
}

.common-table .el-table::before {
  height: 0;
}

.common-table .el-table__empty-block {
  border-bottom: 0.027778rem solid #ddd;
}

.common-table .el-table__header {
  border-collapse: collapse;
}

.common-table .el-table__header th {
  user-select: auto;
}

.common-table .el-table__header th.el-table__cell {
  background-color: #fafafa;
}

.common-table .el-table__header th:last-child {
  border-right: none;
}

.common-table .el-table__body {
  border-collapse: collapse;
}

.common-table thead th {
  background-color: #fafafa;
  height: 1.111111rem;
}

.common-table thead th .cell {
  text-align: left;
  color: #333;
  font-size: 0.388889rem;
  font-weight: normal;
  padding: 0 0.333333rem;
}

.common-table thead th.is-leaf {
  border-bottom: none;
  padding: 0;
}

.common-table tbody tr td {
  text-align: center;
  height: 1.111111rem;
  padding: 0;
}

.common-table tbody tr td .cell {
  text-align: left;
  color: #333;
  font-size: 0.361111rem;
}

.common-table tbody tr td .cell>span {
  font-size: 0.361111rem;
}

.common-table tbody tr td .cell .ellipsis {
  font-size: 0.333333rem;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.common-table tbody tr td:first-child {
  border-left: none;
  padding: 0;
}

.common-table tbody tr td:last-child {
  border-right: none;
}

.common-table tbody tr:hover {
  background-color: #f0f3f8;
}

.common-table tbody tr span,
.common-table tbody tr a {
  font-size: 0.361111rem;
}

.common-table tbody tr .link {
  font-size: 0.361111rem;
  text-decoration: underline;
  color: #1a7ef8;
  cursor: pointer;
}

.common-table .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: transparent;
}

.common-table .el-table__fixed-right-patch {
  background-color: #e9eef8;
}

.common-table .el-checkbox {
  line-height: 1.111111rem !important;
}

.common-table .celi-customer-info {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
}

.common-table .celi-customer-info.center {
  justify-content: center !important;
}

.common-table .celi-customer-info .today-repeat {
  float: left;
}

.common-table .celi-customer-info .today-repeat .icon-repeat {
  font-size: 0.666667rem;
  line-height: 1.166667rem;
  color: #e99417;
  cursor: pointer;
}

.common-table .celi-customer-info .today-repeat .icon-repeat .repeat-count {
  position: relative;
  left: -0.444444rem;
  top: -0.138889rem;
  font-size: 0.333333rem;
}

.common-table .celi-customer-info .info {
  width: 3.888889rem;
  display: inline-block;
}

.common-table .celi-customer-info .info span {
  height: 0.583333rem;
  display: inline-block;
  width: 100%;
  line-height: 0.583333rem;
  color: #0078d7;
}

.common-table .celi-customer-info .all-repeat {
  cursor: pointer;
  color: #ff6868;
  float: right;
  border: 0.027778rem solid #ff6868;
  border-radius: 0.055556rem;
  padding: 0.055556rem;
}

.common-table .celi-customer-info .all-repeat span {
  font-weight: bold;
  font-size: 0.333333rem;
}

.table-opt-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 0.277778rem;
}

.table-opt-btn span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table-opt-btn span.call b {
  color: #06913f;
}

.table-opt-btn b {
  color: #1296db;
  font-size: 0.555556rem;
  margin-bottom: 0.138889rem;
}

.common-pagination {
  margin: 0.555556rem 0;
  display: flex;
  justify-content: flex-end;
}

.el-button--primary.page-table-btn {
  height: 0.833333rem;
  padding: 0 0.333333rem;
}

.el-button--primary.page-table-btn>span {
  line-height: 0.833333rem;
}

.el-button--primary.page-table-btn.red {
  background-color: #ff2f2f;
  border-color: #ff2f2f;
}

.el-button--primary.page-table-btn.gray {
  background-color: #aaa;
  border-color: #aaa;
}

.el-button--primary.page-table-btn.green {
  background-color: #0ca319;
  border-color: #0ca319;
}

.el-button--danger.page-table-btn {
  height: 0.833333rem;
  padding: 0 0.333333rem;
  background-color: #ff2f2f;
  border-color: #ff2f2f;
}

.el-button--danger.page-table-btn>span {
  color: #fff;
  line-height: 0.833333rem;
}

.el-button--danger.page-table-btn:active {
  opacity: .6;
}

.color.normal {
  color: #1a7ef8 !important;
}

.color.red {
  color: #ff5855 !important;
}

.color.orange {
  color: #ff9c11 !important;
}

.color.green {
  color: #0ca319 !important;
}

.link-btn {
  cursor: pointer;
  display: inline-block;
}

.link-btn:hover {
  text-decoration: underline;
}

.link-btn.disabled {
  pointer-events: none;
  color: #ccc !important;
}

.link-btn.disabled:hover {
  text-decoration: none;
}

.cell .link-btn {
  margin: 0 0.138889rem;
}

.form-input-area {
  color: #666;
}

.form-input-area .form-top-lines {
  margin-top: 0.555556rem;
  margin-bottom: 0.555556rem;
  display: flex;
  justify-content: space-between;
}

.form-input-area .el-form {
  padding-top: 0.555556rem;
  max-width: 23.611111rem;
}

.form-input-area .el-form-item {
  margin-bottom: 0.555556rem;
}

.form-input-area span {
  color: #666;
}

.form-submit-line {
  max-width: 23.611111rem;
  text-align: center;
}

.page-cap-title {
  font-weight: bold;
  font-size: 0.666667rem;
  background: #fff;
  padding: 0 0.555556rem;
  line-height: 1.777778rem;
  color: #333;
  border-bottom-left-radius: 0.138889rem;
  border-bottom-right-radius: 0.138889rem;
  border-bottom: 0.027778rem solid #eaeaea;
}

.sub-welcome-page {
  display: flex;
  flex-direction: column;
}

.sub-welcome-page .show-pic {
  width: 8.333333rem;
  height: 7.361111rem;
}

.sub-welcome-page .text {
  text-align: center;
  color: #1a7ef8;
  font-weight: bold;
  padding-top: 0.555556rem;
  font-size: 0.555556rem;
}

.icon-phone-27C93F {
  color: #27c93f !important;
}

.hbb-callIcon-btn {
  font-size: 0.722222rem !important;
  color: #27c93f !important;
  cursor: pointer;
}

.err-text {
  color: red;
  display: flex;
  justify-content: center;
  width: 100%;
}

.el-slider__runway {
  height: 0.277778rem !important;
}

.el-slider__button {
  width: 0.555556rem !important;
  height: 0.555556rem !important;
}

.el-loading-spinner .path {
  stroke: #ccc !important;
}

.el-loading-spinner .el-loading-text {
  color: #ccc !important;
}

.hbb-table-content {
  width: 100%;
  background: #fff;
  padding: 0.555556rem 0.555556rem;
  margin-top: 0.555556rem;
  border-radius: 0.138889rem;
}

.hbb-table-content .top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.555556rem;
}

.hbb-noData {
  display: flex;
  width: 100%;
  height: 4.166667rem;
  justify-content: center;
  align-items: center;
}

.form-radio-group .el-radio-button__inner {
  padding: 0.333333rem 0;
  width: 2.222222rem;
  border: 0.027778rem solid #dcdfe6;
}

.outbound-add-follow .base-form {
  padding: 0 4.166667rem;
}

.el-select__tags,
.el-cascader__tags {
  text-overflow: ellipsis;
  overflow: hidden;
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  height: 0.777778rem;
}

.lead-follow-up .item {
  margin-bottom: 0.555556rem;
}

.felx-column {
  flex-direction: column !important;
}

.felx-column .item {
  margin-top: 0.416667rem !important;
}

.mas-dropdown-menu {
  display: flex;
  flex-direction: column;
  width: 3.611111rem;
  justify-content: center;
  align-items: center;
}

.mas-dropdown-menu li {
  width: 100%;
  height: 1.111111rem;
  padding: 0;
  float: left;
  justify-content: center;
  line-height: 1.111111rem;
  border-bottom: 0.027778rem solid #ddd;
}

.mas-dropdown-menu li:last-child {
  border-bottom: none;
}

.medium-item {
  height: 1.111111rem;
  display: flex;
  align-items: center;
  padding-top: 0.138889rem;
  width: 3.611111rem;
  border-radius: 0.083333rem;
  justify-content: center;
  background: #2d75d4;
}

.medium-item .el-dropdown-link {
  color: #fff;
  font-size: 0.388889rem;
  display: flex;
  align-items: center;
}

.medium-item .el-dropdown-link .dot {
  display: block;
  width: 0.277778rem;
  height: 0.277778rem;
  border-radius: 50%;
  margin-right: 0.138889rem;
  background: red;
}

.el-textarea__inner {
  box-shadow: 0 0 0 0.027778rem #dcdfe6 inset !important;
}

.el-textarea__inner:hover {
  box-shadow: 0 0 0 0.027778rem #c0c4cc inset !important;
}

.el-textarea__inner:focus {
  box-shadow: 0 0 0 0.027778rem #c0c4cc inset !important;
}

.tooltip-warning {
  display: inline-block;
  width: 0.416667rem;
  height: 0.416667rem;
  line-height: 0.416667rem;
  background: #f5be7f;
  color: #fff;
  color: #fff;
  font-size: 0.333333rem;
  border-radius: 50%;
  margin-right: 0.111111rem;
}

.mass-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mass-tool .left {
  line-height: 1.166667rem;
}

.mass-tool .left .desc {
  border-right: 0.027778rem solid #000;
  margin-right: 0.555556rem;
  padding-right: 0.555556rem;
}

.mass-tool .left .selections {
  margin-right: 0.277778rem;
}

.mass-tool .left .selections .select-count {
  color: #298df8;
}

.mass-tool .left .el-button {
  margin: 0 0.555556rem;
}

.no-scroll ::-webkit-scrollbar {
  display: none;
}