.tip-box {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-right: 0.416667rem;
}

.tip-box .tip-box-cell {
  overflow: hidden;
}

.tip-box .content-button {
  position: absolute;
  right: 0.138889rem;
  top: 0;
  cursor: pointer;
}

.tip-box .content-button.rotate {
  transform: rotate(90deg);
  right: -0.083333rem;
  top: 0.138889rem;
}

.tip-box .content-button.rotate * {
  color: #159aff;
  letter-spacing: 0.041667rem;
  font-size: 0.444444rem;
}