h2 {
  display: flex;
  width: 100%;
  margin: 0.277778rem 0;
  font-size: 0.555556rem;
}

.tipText {
  color: red;
  text-align: center;
}