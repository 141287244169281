.preview-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 0.027778rem solid #ddd;
  padding: 0.277778rem;
}

.preview-content .label {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.preview-content .label span {
  cursor: pointer;
  color: #2d75d4;
  font-size: 0.333333rem;
}

.preview-content .textarea {
  margin: 0.277778rem 0;
}

.preview-content .enclosure {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.preview-content .enclosure .noList {
  cursor: pointer;
}

.preview-content .enclosure .noList .add {
  color: #2d75d4;
  font-size: 0.333333rem !important;
}