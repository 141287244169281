.mas-table-pagination {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.mas-table-pagination .el-table .pointer {
  cursor: pointer;
}

.mas-table-pagination .el-table .color-2d75d4 {
  color: #2d75d4;
}

.mas-table-pagination .el-table .el-table__header-wrapper {
  overflow: hidden;
  display: flex;
  height: 1.111111rem;
  align-items: center;
}

.mas-table-pagination .mas-table-opt-button {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.mas-table-pagination .mas-table-opt-button span {
  color: #159aff;
  font-size: 0.333333rem;
  cursor: pointer;
}

.mas-table-pagination .mas-table-opt-button span.disable {
  color: #ccc;
}