.opt {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.opt span {
  display: flex;
  width: 100%;
  align-items: center;
  height: 0.833333rem;
}

.opt span span {
  width: 0.222222rem;
  height: 0.222222rem;
  border-radius: 50%;
  margin-right: 0.138889rem;
}