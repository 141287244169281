.bounced {
  position: absolute;
  top: 2.777778rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 13.888889rem;
  height: 8.333333rem;
  background-color: #fff;
  border: 0.027778rem solid #ccc;
}

.header {
  height: 1.111111rem;
  padding: 0 0.277778rem;
  line-height: 1.388889rem;
}

.header span {
  width: 2.777778rem;
}

.header #icon-close {
  position: absolute;
  right: 0.555556rem;
  top: 0.416667rem;
  width: 0.555556rem;
  height: 0.555556rem;
}

.header #icon-close:hover {
  cursor: pointer;
}

.icon {
  position: absolute;
  width: 0.555556rem;
  height: 0.555556rem;
}

.content {
  padding: 1.388889rem;
  text-align: center;
}

.btns {
  padding: 0 1.944444rem;
}

.btns button {
  width: 2.777778rem;
}