.mask {
  background-color: #fff;
  height: 0.277778rem;
  width: 0.277778rem;
  position: absolute;
  left: -0.083333rem;
  top: 0.277778rem;
}

.box-card {
  width: 18.055556rem;
  border: solid 0.027778rem #c9c5c5;
  padding: 0.277778rem;
}