.upload-button-area {
  display: flex;
  width: 2.777778rem;
  height: 2.777778rem;
  justify-content: center;
  align-items: center;
  border: 0.027778rem solid #ddd;
  flex-direction: column;
}

.upload-button-area span {
  font-size: 0.333333rem;
}

.upload-button-area span:nth-of-type(1) {
  font-size: 0.555556rem;
  font-weight: bold;
  margin-bottom: 0.138889rem;
}