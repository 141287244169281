.mass-tool {
  width: 100%;
  min-height: 1.166667rem;
}

.mass-tool .left {
  line-height: 1.166667rem;
  float: left;
}

.mass-tool .left .desc {
  border-right: 0.027778rem solid #000;
  margin-right: 0.555556rem;
  padding-right: 0.555556rem;
}

.mass-tool .left .selections {
  margin-right: 0.277778rem;
}

.mass-tool .left .selections .select-count {
  color: #298df8;
}

.mass-tool .left .el-button {
  margin: 0 0.555556rem;
}

.mass-tool .right {
  float: right;
}

.mass-tool .right .el-button {
  height: 0.777778rem;
}

.mass-tool .right .el-button span {
  font-size: 0.333333rem;
}