.mas-upload {
  width: 100%;
}

.mas-upload .upload-demo {
  width: 100%;
}

.mas-upload .upload-demo.center {
  display: flex;
  justify-content: center;
}

.mas-upload .upload-demo.end {
  display: flex;
  justify-content: flex-end;
}

.mas-upload .upload-demo .el-upload-dragger {
  width: auto;
  height: auto;
  border: none;
}

.mas-upload .upload-demo .el-upload-list .el-upload-list__item .el-upload-list__item-info .el-upload-list__item-name {
  display: block;
  padding-right: 1.111111rem;
  text-align: left;
}

.mas-upload .upload-demo .el-upload-list .el-upload-list__item .el-upload-list__item-info .el-upload-list__item-name .el-upload-list__item-file-name {
  display: inline-block;
  width: calc(100% - 0.777778rem);
}

.mas-upload .tip {
  margin-top: 0.277778rem;
  font-size: 0.277778rem;
}