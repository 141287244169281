.mas-no-data {
  display: flex;
  width: 100%;
  height: 8.333333rem;
  justify-content: center;
  align-items: center;
}

.mas-no-data .mas-no-data-title {
  color: #666;
}