.hbb-select-call-mobile-modal .con {
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
}

.hbb-select-call-mobile-modal .con div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.hbb-select-call-mobile-modal .con div span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.166667rem;
  background: #ccc;
  padding: 0.277778rem 0;
  border-radius: 0.138889rem;
  font-size: 0.388889rem;
}

.hbb-select-call-mobile-modal .con div span b {
  font-weight: normal;
}

.hbb-select-call-mobile-modal .con div span:hover {
  background: #283237;
  color: #fff;
}