.dialog-main-content {
  display: flex;
  flex-direction: column;
}

.dialog-main-content .tip {
  display: flex;
  width: 100%;
  background: #eee;
  padding: 0.277778rem;
  font-size: 0.333333rem;
}

.dialog-main-content .tip span {
  color: #2d75d4;
  font-size: 0.5rem;
  margin-right: 0.138889rem;
}

.dialog-main-content .item {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0.555556rem;
}

.dialog-main-content .item .left {
  width: 2.777778rem;
}

.dialog-main-content .item .right {
  width: calc(100% - 2.777778rem);
}

.dialog-main-content .item.download .right {
  color: #2d75d4;
  cursor: pointer;
}

.dialog-main-content .item.download .right b {
  margin-right: 0.138889rem;
}

.dialog-main-content .item.download .right .upload-own .el-upload-dragger {
  width: auto !important;
  height: auto !important;
  border: none !important;
}

.dialog-main-content .item.select-table .right {
  display: flex;
  flex-direction: column;
  padding: 0.277778rem 0.555556rem;
  background: #eee;
}

.dialog-main-content .item.select-table .right p {
  margin-bottom: 0.277778rem;
  font-size: 0.333333rem;
}

.download-button {
  display: flex;
  margin-top: 0.277778rem;
  justify-content: flex-end;
}