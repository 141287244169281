.item {
  display: flex;
  width: 100%;
}

.item .label {
  width: 3.055556rem;
}

.item .value {
  width: calc(100% - 3.055556rem);
}

.item .openCon {
  margin-left: 0.277778rem;
  cursor: pointer;
  color: #409eff;
}