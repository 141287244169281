.cart,
.ball {
  position: fixed;
  z-index: 300000;
}

.cart {
  border: 0.027778rem solid blue;
  padding: 0.138889rem 0.333333rem;
}

.ball {
  width: 2.777778rem;
  height: 2.777778rem;
}

.innerBall {
  width: 0.277778rem;
  height: 0.277778rem;
}