.report-page {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.report-page h2 {
  display: flex;
  align-items: center;
  font-size: 0.416667rem;
  color: #333;
  position: relative;
}

.report-page h2 span {
  width: 0.555556rem !important;
  display: flex;
  align-items: center;
}

.report-page h2 .svgBtn {
  border: none;
  height: 0.555556rem;
  display: flex;
  width: 0.555556rem !important;
  padding: 0;
}

.report-page h2 .svgBtn span {
  line-height: 0;
}

.report-page .charts {
  margin-top: 0.555556rem;
}

.report-page .charts .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.277778rem 0.138889rem;
  margin-bottom: 0;
}

.report-page .charts .right {
  margin-left: 0.416667rem;
}

.report-page .charts .right img {
  width: 0.555556rem;
  height: 0.555556rem;
  cursor: pointer;
}

.report-page .chart-map {
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-page .chart-map canvas {
  width: 100%;
}

.report-page .chart-map.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8.333333rem;
}

.no-chart-info {
  text-align: center;
  padding: 0.555556rem 0;
  color: #f2f2f2;
}