.mas-table-opt-button {
  display: flex;
  justify-content: space-between;
}

.mas-table-opt-button span {
  color: #2d75d4;
  font-size: 0.333333rem;
  cursor: pointer;
}

.page-cap-title {
  padding: 0.555556rem;
  background: #fff;
  margin-bottom: 0.277778rem;
}

.page-cap-title span {
  font-size: 0.666667rem;
  color: #333;
  margin-right: 0.416667rem;
  cursor: pointer;
}

.page-cap-title span.active {
  color: #409eff;
}