::v-deep .header-row-class-name {
  --el-table-header-bg-color: #f5f7fa;
}

::v-deep .header-row-class-name .el-table-fixed-column--right,
::v-deep .header-row-class-name .el-table-fixed-column--left {
  background-color: #f5f7fa;
}

.mas-table {
  margin-top: 0.277778rem;
  background-color: #fff;
  padding: 0 0.277778rem;
}

.mas-table .table-tool-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.277778rem 0;
}

.mas-table .table-tool-bar .selected-tip {
  color: #2f3030;
  font-size: 0.388889rem;
}

.mas-table .table-tool-bar .selected-tip .number {
  color: #159aff;
}

.mas-table .pagination {
  display: flex;
  align-items: center;
  margin: 0.555556rem 0;
}