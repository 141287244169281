.input-section {
  padding: 0 1.805556rem 0.833333rem;
  border-radius: 0.138889rem;
  width: 13.888889rem;
}

.input-section .title {
  text-align: center;
  font-size: 0.722222rem;
  line-height: 1.388889rem;
  border-bottom: 0.027778rem solid #ddd;
}

.input-section .el-form-item {
  margin: 0;
}

.input-section .input-title {
  font-size: 0.388889rem;
  font-weight: 400;
  line-height: 0.611111rem;
  letter-spacing: .0.694444rem;
  text-align: left;
  margin-top: 0.888889rem;
  color: #fff;
}

.input-section .input-line {
  display: flex;
  align-items: center;
  margin-top: 0.277778rem;
}

.input-section .input-line .label {
  width: 1.388889rem;
  display: inline-block;
  margin-right: 0.277778rem;
  text-align: right;
  font-size: 0.666667rem;
  color: #333;
}

.input-section .input-line .forget-password {
  font-size: 0.388889rem;
  color: #333;
  cursor: pointer;
  user-select: none;
}

.input-section .input-line .input-text,
.input-section .input-line .input-password {
  width: 10rem;
}

.input-section .input-line .input-code {
  width: 3.888889rem;
}

.input-section .input-line .code-img {
  width: 1.944444rem;
  height: 1.166667rem;
  display: inline-block;
  margin: 0 0.277778rem;
}

.input-section .input-line .refresh-btn {
  display: inline-block;
  color: #1a7ef8;
  font-size: 0.388889rem;
  line-height: 1.166667rem;
  cursor: pointer;
  user-select: none;
}

.input-section .error-text {
  display: flex;
  width: 100%;
  margin-top: 0.555556rem;
  margin-bottom: -0.277778rem;
  align-items: center;
}

.input-section .error-text span {
  color: red;
  margin-left: 0.277778rem;
}

.input-section .error-text span:nth-of-type(1) {
  margin-left: 0;
  font-weight: bold;
}

.input-section .login-btn {
  width: 100%;
  height: 1.222222rem;
  margin-top: 0.888889rem;
  border: none;
  background: #4698cb;
  background-size: 1400% 300%;
  animation: dynamics 6s ease infinite;
  -webkit-animation: dynamics 6s ease infinite;
  -moz-animation: dynamics 6s ease infinite;
}

.input-section .login-last {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 1.388889rem;
  align-items: center;
  color: #fff;
}

.input-section .login-last .left {
  display: flex;
  align-items: center;
  font-size: 0.444444rem;
  margin-top: 0.722222rem;
}

.input-section .login-last .left .el-checkbox {
  display: flex;
  align-items: center;
  margin-right: 0.138889rem;
}

.input-section .login-last a {
  color: #2593fc;
}

.input-section .icon-password {
  display: flex;
  align-items: center;
  font-size: 0.777778rem;
  color: #101010;
  cursor: pointer;
}