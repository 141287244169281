.mas-preview {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 13.888889rem;
  background: url(./part/images/preview-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.mas-preview .title {
  position: absolute;
  top: -0.833333rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.388889rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: bold;
  color: #333;
}

.mas-preview .content {
  position: absolute;
  height: 11.944444rem;
  width: 100%;
  overflow-y: auto;
  top: 0.555556rem;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-left-radius: 0.555556rem;
  border-bottom-right-radius: 0.555556rem;
  padding: 0.555556rem;
}

.mas-preview .content .item {
  font-size: 0.333333rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #666;
  display: flex;
  margin-top: 0.555556rem;
}

.mas-preview .content .item:first-child {
  margin-top: 0;
}

.mas-preview .content .item .avatar {
  margin-right: 0.138889rem;
}

.mas-preview .content .item .msg {
  position: relative;
  min-height: 0.722222rem;
  background: #fff;
  box-shadow: 0 0.055556rem 0.166667rem 0 rgba(60,136,240,.1);
  border-radius: 0.138889rem;
  display: inline-block;
  border-radius: 0.111111rem;
  box-sizing: border-box;
  word-break: break-all;
  margin-left: 0.138889rem;
}

.mas-preview .content .item .msg.fill {
  width: 100%;
}

.mas-preview .content .item .msg::before {
  content: " ";
  display: none;
  position: absolute;
  left: -0.138889rem;
  border-width: 0.138889rem;
  border-left: 0;
  width: 0;
  border-style: solid;
  border-color: transparent;
  border-right-color: #94eb69;
  top: 0.222222rem;
  z-index: 888;
}

.mas-preview .content .item .msg .word {
  padding: 0.138889rem 0.333333rem;
  background: #94eb69 !important;
}

.mas-preview .content .item .msg .image {
  padding: 0.277778rem;
}

.mas-preview .content .item .msg .image img {
  width: 2.777778rem;
  height: 2.777778rem;
}

.mas-preview .content .item .msg .word-and-image {
  display: flex;
}

.mas-preview .content .item .msg .word-and-image .sub-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.333333rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  border-radius: 0.138889rem;
  color: #999;
}

.mas-preview .content .item .msg .word-and-image .sub-content.link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.138889rem 0.277778rem;
}

.mas-preview .content .item .msg .word-and-image .sub-content.link .link-title {
  font-weight: bold;
  width: 100%;
}

.mas-preview .content .item .msg .word-and-image .sub-content.link .link-title p:nth-of-type(1) {
  font-size: 0.333333rem;
}

.mas-preview .content .item .msg .word-and-image .sub-content.link .link-title p:nth-of-type(2) {
  margin-top: 0.138889rem;
  font-weight: normal;
  font-size: 0.277778rem;
}

.mas-preview .content .item .msg .word-and-image .sub-content.link .link-img img {
  width: 0.833333rem;
  height: 0.833333rem;
}

.mas-preview .content .item .msg .word-and-image .sub-content.applet {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.138889rem 0.277778rem;
}

.mas-preview .content .item .msg .word-and-image .sub-content.applet .applet-title {
  font-weight: bold;
  font-size: 0.388889rem;
  margin-bottom: 0.277778rem;
}

.mas-preview .content .item .msg .word-and-image .sub-content.applet .applet-img {
  width: 100%;
}

.mas-preview .content .item .msg .word-and-image .sub-content.applet .applet-img img {
  width: 100%;
}

.mas-preview .content .item .msg .word-and-image .sub-content .video-image {
  display: flex;
  width: 3.333333rem;
  height: 3.333333rem;
  border-radius: 0.138889rem;
  overflow: hidden;
  position: relative;
  padding: 0.277778rem;
}

.mas-preview .content .item .msg .word-and-image .sub-content .video-image span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 2;
  background: #000;
  opacity: .8;
  font-size: 0.722222rem;
}

.mas-preview .content .item .msg .word-and-image .sub-content .video-image img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.mas-preview .content .item .msg .miniprogram {
  padding: 0.333333rem;
}

.mas-preview .content .item .msg .miniprogram .mini-header {
  display: flex;
  align-items: center;
}

.mas-preview .content .item .msg .miniprogram .mini-header img {
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.138889rem;
}

.mas-preview .content .item .msg .miniprogram .mini-img {
  margin: 0.277778rem 0;
  width: 3.972222rem;
  height: 3.333333rem;
}

.mas-preview .content .item .msg .miniprogram .mini-footer {
  display: flex;
  align-items: center;
}

.mas-preview .content .item .msg .miniprogram .mini-footer img {
  height: 0.388889rem;
  width: 0.388889rem;
  margin-right: 0.138889rem;
}