.enclosure-type {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.enclosure-type .group {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  text-align: center;
}

.enclosure-type .group p:nth-of-type(1) {
  font-size: 0.5rem;
  color: #2d75d4;
}

.enclosure-type .group p:nth-of-type(2) {
  font-size: 0.333333rem;
  margin-top: 0.138889rem;
}