.video-box {
  display: flex;
  width: 95%;
  flex-direction: column;
}

.video-box .video_control {
  margin: 0 auto;
  display: flex;
  width: 93%;
  padding-top: 0.555556rem;
  background-color: #fff;
  align-items: center;
  position: relative;
}

.video-box .video_control .btn {
  display: flex;
  margin-left: 0.277778rem;
}

.video-box .video_control .p_img {
  width: 0.833333rem;
  height: 0.833333rem;
  font-size: 0.777778rem;
}

.video-box .video_control .custom-button {
  width: 0.972222rem;
  height: 0.972222rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.666667rem;
  text-align: center;
  background: #fff;
  border: 0.111111rem solid #bceeff;
  border-radius: 50%;
}

.video-box .video_control .progress {
  width: calc(100% - 1.666667rem);
  position: relative;
  height: 0.666667rem;
  display: flex;
  left: 0.555556rem;
  top: 0.083333rem;
  align-items: center;
  cursor: default;
  user-select: none;
  outline: none;
}

.video-box .video_control .commonSlider {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 0.833333rem;
}

.video-box .video_control .p_slider_fill {
  position: absolute;
  height: 0.277778rem;
  width: 100%;
  background-color: #bceeff;
  opacity: .8;
  left: 0;
  top: 50%;
  border-radius: 0.833333rem;
  margin-top: -0.027778rem;
}

.video-box .video_control .p_slider_thumb {
  position: absolute;
  top: 60%;
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 0.111111rem solid #bceeff;
  color: #ffcd3a;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.video-box .time-box {
  display: flex;
  width: calc(100% - 2.777778rem);
  margin-top: 0.555556rem;
  padding: 0 0.416667rem;
  margin-left: 2.777778rem;
  justify-content: space-between;
}