.mas-form-pc {
  width: 100%;
}

.mas-form-pc .el-form {
  width: 100%;
}

.mas-form-pc .el-form .mas-form-pc-content {
  display: flex;
}

.mas-form-pc .el-form .mas-form-pc-content .data-report-btn-title {
  color: #2d75d4;
  cursor: pointer;
  margin-left: 0.277778rem;
  font-size: 0.666667rem;
  font-weight: bold;
}

.mas-form-pc .el-form .mas-form-pc-content .data-report-btn-title img {
  width: 0.694444rem;
}

.mas-form-pc .el-form .mas-form-pc-content .data-report-btn-title {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.mas-form-pc .el-form .el-form-item {
  width: auto;
  margin-right: 0.555556rem;
}

.mas-form-pc .el-form .el-form-item.el-form-item-footer .el-form-item__content {
  justify-content: center;
}

.mas-form-pc .el-form .el-form-item.el-form-item-footer .el-form-item__content .el-button {
  height: 0.777778rem;
}

.mas-form-pc .el-form .el-form-item.el-form-item-footer .el-form-item__content .el-button span {
  font-size: 0.333333rem;
}

.mas-form-pc .el-form .el-form-item.el-form-item-footer.inline {
  float: right;
}