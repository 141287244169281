.common-high-radius-wapper {
  background-color: #fff;
  padding: 0.444444rem 0.666667rem;
  margin-bottom: 0.277778rem;
  border-radius: 0.138889rem;
}

.common-high-radius-wapper.column {
  flex-direction: column;
}

.common-high-radius-wapper.no-margin {
  margin-top: 0;
}

.common-high-radius-wapper .zqw-screen-box {
  width: 100%;
  background: #fff;
  border-radius: 0.138889rem;
}

.common-high-radius-wapper .zqw-screen-box .zqw-top-opt {
  display: flex;
  width: 100%;
  margin-bottom: 0.555556rem;
  justify-content: space-between;
}

.common-high-radius-wapper .zqw-screen-box .zqw-top-opt span {
  font-size: 0.5rem;
  cursor: pointer;
}

.common-high-radius-wapper .zqw-screen-box .zqw-top-opt span:nth-of-type(2) {
  color: #1296db;
}

.common-high-radius-wapper .zqw-screen-box .el-form-item {
  margin-right: 0.555556rem !important;
}