.el-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-overlay .el-overlay-dialog {
  display: flex;
}

.el-overlay .el-dialog {
  margin: auto !important;
  max-height: 100vh;
  border-radius: 0.138889rem;
  overflow: hidden;
}

.el-overlay .el-dialog .el-dialog__body {
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 5.055556rem);
}

.el-overlay .el-dialog .el-dialog__wrapper {
  overflow: hidden;
}

.el-overlay .el-dialog.mas-dialog-no-header .el-dialog__header {
  display: none !important;
}