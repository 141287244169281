.common-high-tab-bar .el-tabs__item {
  font-size: 0.5rem;
  color: #101010;
  font-weight: bold;
  height: 1.388889rem;
  line-height: 1.388889rem;
}

.common-high-tab-bar .el-tabs__item.is-active {
  color: #298df8;
}

.common-high-tab-bar .el-tabs__active-bar {
  background: #298df8;
}

.common-high-tab-bar .el-tabs__nav-wrap::after {
  height: 0;
}

.common-high-tab-bar .el-tabs__header {
  margin: 0 0 0.277778rem;
}

.common-high-tab-bar.small .el-tabs__item {
  font-size: 0.333333rem;
  color: #101010;
  font-weight: normal;
  height: 0.888889rem;
  line-height: 0.888889rem;
  padding-right: 0.277778rem;
  padding-left: 0.277778rem;
}

.common-high-tab-bar.small .el-tabs__item.is-active {
  color: #298df8;
}

.common-high-tab-bar.small .el-tabs__nav.is-stretch>* {
  flex: none;
}