.collapsable-wapper {
  margin-top: 0.555556rem;
  padding: 0.333333rem 0 0.333333rem 0.555556rem;
  width: 100%;
  background: #fff;
  border-radius: 0.138889rem;
}

.collapsable-wapper .expand-wapper {
  display: flex;
  width: 100%;
  margin: 0.222222rem 0 0.333333rem 0;
  justify-content: space-between;
}

.collapsable-wapper .expand-wapper .right {
  margin-right: 0.555556rem;
}

.collapsable-wapper .expand-wapper span {
  font-size: 0.5rem;
  cursor: pointer;
}

.collapsable-wapper .expand-wapper span:nth-of-type(2) {
  color: #1296db;
}

.collapsable-wapper .form-wapper {
  padding: 0;
}